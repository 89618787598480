import tataImg from "../../assets/images/tata.png"
import upIcon from "../../assets/images/up.png"
import landRoverImg from "../../assets/images/lr.png"
import holdIcon from "../../assets/images/hold.png"


function MarketCaps() {
    return (
      <div>
      <div className="banner-section inner-banner height">
        <div className="banner-divider">
          <div className="inbanner-text">
            <h1>MARKET CAPS</h1>
            <p className="mrg-10">Read and explore 5000+ reports and start growing today! </p>
          </div>
        </div>
      </div>
      <div className="market-cap spacing green-bg">
        <div className="container">
          <div className="cap-box">
            <figure className="mc-title">SMALL CAP</figure>
            <p>Explore potential investments in companies with market capitalisation up to ₹5,000 crores. </p>
            <div className="cap-divider">
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn gray"><img src={holdIcon} alt="holdIcon" /><br /> Hold</div>
                </div>
              </div>
            </div>
            <a href={'/'} className="button">SEE MORE REPORTS</a>
          </div>
          <div className="cap-box">
            <figure className="mc-title">MID CAP</figure>
            <p>Explore potential investments in companies with market capitalisation ranging from ₹5,000 crores to ₹20,000 crores.</p>
            <div className="cap-divider">
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn gray"><img src={holdIcon} alt="holdIcon" /><br /> Hold</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</div>
                </div>
              </div>
            </div>
            <a href={'/'} className="button">SEE MORE REPORTS</a>
          </div>
          <div className="cap-box">
            <figure className="mc-title">LARGE CAP</figure>
            <p>Explore potential investments in companies with market capitalisation greater than ₹20,000 crores. </p>
            <div className="cap-divider">
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn gray"><img src={holdIcon} alt="holdIcon" /><br /> Hold</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</div>
                </div>
              </div>
            </div>
            <a href={'/'} className="button">SEE MORE REPORTS</a>
          </div>
        </div>
      </div>
      <hr className="white-border" />
    </div>
      )
    }
    
    export default MarketCaps;
 