import error from "../../assets/images/error.svg";

function ErrorPage() {
    return (
        <div>
        <section className="errorpage contact-page green-bg spacing">
          <div className="container">
            <div className="error-message">
              <img className="msg-img" src={error} alt="error" />
              <span className="msgtext">PAGE NOT FOUND!</span>
              <a href={'/'} className="button">GO BACK HOME</a>
            </div>
          </div>
        </section>
        <hr className="white-border" />
      </div>
          )
}

export default ErrorPage;