import './App.css';
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import SmallCap from './pages/SmallCap/SmallCap';
import LargeCap from './pages/LargeCap/LargeCap';
import Login from './pages/Login/Login';
import MarketCaps from './pages/MarketCaps/MarketCaps.js';
import Subscribe from './pages/Subscribe/Subscribe';
import SearchPage from './pages/SearchPage/SearchPage';
import SearchPageX from './pages/SearchPageX/SearchPageX';
import ReportTemplate from './pages/ReportTemplate/ReportTemplate';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import MidCap from './pages/MidCap/MidCap'; 
import history from "./utils/history";
import Header from './components/molecules/Header';
import Footer from './components/molecules/Footer';
import Profile from './pages/Profile/Profile';
import Report from './pages/Report/Report';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions.js/TermsAndConditions';
import PrivateRoutes from './services/PrivateRoutes';
import { COGNITO } from './configs/aws';
import { AuthProvider } from './utils/AuthContext';

Amplify.configure({
  Auth: {
    Cognito: {
      region: COGNITO.REGION,
      userPoolId: COGNITO.USER_POOL_ID,
      userPoolClientId: COGNITO.APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: 'auth.stockxprt.com',
          scopes:[
            "email",
            "openid",
            "profile",
            "phone",
            "aws.cognito.signin.user.admin"
         ],
          redirectSignIn: [
            'http://localhost:3000/',
            'https://www.stockxprt.com',
            'https://www.stockxprt.com/login',
            'http://localhost:3000/login'

          ],
          redirectSignOut: [
            'http://localhost:3000/',
            'https://www.stockxprt.com'
          ],
          responseType: 'code'
        }
      }
    }
  }
})

function App() {
  return (
    <AuthProvider>
      <Router history={history}>
        <Header />
        <Routes>
          <Route path="/profile" element={
              <PrivateRoutes>
                <Profile />
              </PrivateRoutes>
            } />
            <Route path="/subscribe" element={
              <PrivateRoutes>
                <Subscribe />
              </PrivateRoutes>
            } />
          {/* <Route element={<Profile />} path="/profile" /> */}
          {/* <Route element={<PrivateRoutes />}>
              <Route element={<Profile/>} path="/profile" exact/>
              <Route path='/subscribe' element={<Subscribe/>} />
          </Route> */}
          <Route path='/' element={<Home/>} />
          <Route path='/contact-us' element={<ContactUs/>} />
          <Route path='/about-us' element={<AboutUs/>} />
          <Route path='/small-cap' element={<SmallCap/>} />
          <Route path='/mid-cap' element={<MidCap/>} /> 
          <Route path='/large-cap' element={<LargeCap/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/market-caps' element={<MarketCaps/>} />
          <Route path='/search-page' element={<SearchPage/>} />
          <Route path='/search-page-x' element={<SearchPageX/>} />
          <Route path='/report' element={<Report/>} />
          <Route path='/error-page' element={<ErrorPage/>} />
          <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions/>} />
        </Routes>
        <Footer />
      </Router>
      </AuthProvider>
  );
}

export default App;
