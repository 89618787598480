import upIcon from "../../assets/images/up.png";
import holdIcon from "../../assets/images/hold.png";
import sellIcon from "../../assets/images/sell.png";
import watermarkImg from "../../assets/images/Watermark.png";
import dailyImg from "../../assets/images/DAILY.jpg";
import weeklyImg from "../../assets/images/WEEKLY.jpg";
import { useState, useEffect } from 'react';
import stockxprtApi from '../../api/stockxprtApi';
import { useSearchParams } from "react-router-dom";

function Report() {
    let [searchParams] = useSearchParams();
    const [reportData, setReportData] = useState(null);
    const [loading, setLoading] = useState(true);
    const callIcons = {'buy': upIcon, 'sell': sellIcon, 'hold': holdIcon}

    const id = searchParams.get('id')

    useEffect(() => {
        setLoading(true);
        stockxprtApi.get(`/report?id=${id}`).then((resp) => {
            setReportData(resp.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err)
        })
    },[])
    if (!loading) {
        return (
            <div>
            <div className="report-page">
                <div className="spacing green-bg">
                <div className="toplogo report">
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%'}}><div style={{fontSize: '32px', width: '50%', color: 'black', fontWeight: 'bold'}}>{reportData.metadata.name} <br /><span style={{fontSize: '12px', fontWeight: 'normal'}}>{reportData.metadata.timestamp}</span></div> <div style={{width: '50%'}}><img style={{width: '100px'}} src={reportData.metadata.logo} alt="tatatickerImg" /></div></div> 
                </div>
                <div className="watermark"><img src={watermarkImg} alt="watermarkImg" /></div>
                <div className="container">
                    <div className="filter-section">
                    <a style={{pointerEvents: 'none', cursor: 'default'}} href={'/'} className="sm-btn"><img src={callIcons[reportData.metadata.trade_call]} alt={reportData.metadata.trade_call} />{reportData? reportData.metadata.trade_call: null}</a>
                    <div className="filter-pera">
                        <figure>
                        CMP
                        <span>{reportData.metadata.cmp}</span>
                        </figure>
                        <figure>
                        Target
                        <span>{reportData.metadata.target_price}</span>
                        </figure>
                        <figure>
                        SL
                        <span>{reportData.metadata.stop_loss}</span>
                        </figure>
                    </div>
                    </div>
                    <div className="perelax-menu">
                    <ul>
                        <li><a href="#overview">OVERVIEW</a></li>
                        <li><a href="#financials">FINANCIALS</a></li>
                        <li><a href="#assumptipns">ASSUMPTIONS &amp; VALUATION</a></li>
                        <li><a href="#analysis">TECHNICAL ANALYSIS</a></li>
                        <li><a href="#conclusion">CONCLUSION</a></li>
                    </ul>
                    </div>
                </div>
                </div>
                <div id="overview" className="overview-section">
                <div className="container">
                    <ul className="report-details">
                        {
                            reportData.data.overview.map((overview) => {
                                return (
                                    <li>{overview}</li>
                                )
                            })
                        }
                    </ul>
                    
                </div>
                </div>
                <div id="financials" className="overview-section whitebg">
                <div className="container">
                    <div className="dpr-box"> <span>FINANCIALS</span> <div className="buybtn"><img src={callIcons[reportData.metadata.trade_call]} alt={reportData.metadata.trade_call} />{reportData? reportData.metadata.trade_call: null}</div>
                    </div>
                    <table className="table">
                    <tbody><tr className="table-header">
                        <th className="cell">NARRATION</th>
                        {
                            reportData.data.financials.table["NARRATION"].map((item) => {
                                return (
                                    <th className="cell">{item}</th>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>SALES</td>
                        {
                            reportData.data.financials.table["SALES"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>EXPENSES</td>
                        {
                            reportData.data.financials.table["EXPENSES"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>OPERATING PROFIT</td>
                        {
                            reportData.data.financials.table["OPERATING PROFIT"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>DEPRECIATION</td>
                        {
                            reportData.data.financials.table["DEPRECIATION"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>PRICE TO EARNING</td>
                            {
                            reportData.data.financials.table["PRICE TO EARNING"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>PRICE</td>
                        {
                            reportData.data.financials.table["PRICE"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>INTEREST</td>
                        {
                            reportData.data.financials.table["INTEREST"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>PROFIT BEFORE TAX</td>
                        {
                            reportData.data.financials.table["PROFIT BEFORE TAX"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>TAX</td>
                        {
                            reportData.data.financials.table["TAX"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>NET PROFIT</td>
                        {
                            reportData.data.financials.table["NET PROFIT"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                    </tbody></table>
                    <ul className="report-details">
                        {
                            reportData.data.financials.data.map((row) => {
                                return (
                                    <li>
                                        {row}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                </div>
                <div id="assumptipns" className="overview-section whitebg">
                <div className="container">
                    <div className="dpr-box"> <span>ASSUMPTIONS &amp; VALUATION</span> <div className="buybtn"><img src={callIcons[reportData.metadata.trade_call]} alt={reportData.metadata.trade_call} />{reportData? reportData.metadata.trade_call: null}</div></div>
                    <table className="table">
                    <tbody><tr className="table-header">
                        <th className="cell">NARRATION</th>
                        {
                            reportData.data.assumptions_valuations.table["NARRATION"].map((item) => {
                                return (
                                    <th className="cell">{item}</th>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>SALES</td>
                        {
                            reportData.data.assumptions_valuations.table["SALES"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>EXPENSES</td>
                        {
                            reportData.data.assumptions_valuations.table["EXPENSES"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>OPERATING PROFIT</td>
                        {
                            reportData.data.assumptions_valuations.table["OPERATING PROFIT"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>DEPRECIATION</td>
                        {
                            reportData.data.assumptions_valuations.table["DEPRECIATION"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>PRICE TO EARNING</td>
                            {
                            reportData.data.assumptions_valuations.table["PRICE TO EARNING"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>PRICE</td>
                        {
                            reportData.data.assumptions_valuations.table["PRICE"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>INTEREST</td>
                        {
                            reportData.data.assumptions_valuations.table["INTEREST"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>PROFIT BEFORE TAX</td>
                        {
                            reportData.data.assumptions_valuations.table["PROFIT BEFORE TAX"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>TAX</td>
                        {
                            reportData.data.assumptions_valuations.table["TAX"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                        <tr>
                        <td>NET PROFIT</td>
                        {
                            reportData.data.assumptions_valuations.table["NET PROFIT"].map((item) => {
                                return (
                                    <td>{item}</td>
                                )
                            })
                        }
                        </tr>
                    </tbody></table>
                    <div className="basic">
                    <div className="basic-auto">
                        <span>Basic Assumptions:</span>
                        <ul>
                        <li>Growth rate: {reportData.data['assumptions_valuations'].assumptions['growth_rate']}</li>
                        <li>Rate of interest: {reportData.data['assumptions_valuations'].assumptions['rate_of_interest']}</li>
                        </ul>
                        <hr className="purpal" />
                        <p className="ppr">The calculated price as per our method of valuation is
                        <em>{reportData.data['assumptions_valuations'].valuation}</em>
                        </p>
                    </div>
                    </div>
                </div>
                </div>
                <div id="analysis" className="overview-section whitebg pattern">
                <div className="container">
                    <div className="dpr-box"> <span>TECHNICAL ANALYSIS</span> <div className="buybtn"><img src={callIcons[reportData.metadata.trade_call]} alt={reportData.metadata.trade_call} />{reportData? reportData.metadata.trade_call: null}</div></div>
                    <div className="analysis">
                    <img src={reportData.data.technical_analysis.weekly_chart} alt="weeklyImg" />
                    <img src={reportData.data.technical_analysis.daily_chart} alt="dailyImg" />
                    </div>
                    <p>{reportData.data.technical_analysis.data}</p>
                </div>
                </div>
                <div id="conclusion" className="overview-section whitebg">
                <div className="container">
                    <div className="dpr-box"> <span>CONCLUSION</span> <div className="buybtn"><img src={callIcons[reportData.metadata.trade_call]} alt={reportData.metadata.trade_call} />{reportData? reportData.metadata.trade_call: null}</div></div>
                    <div className="conclusion">
                    <div className="con-divider">
                        <figure> TARGET<span>{reportData.metadata.target_price}</span></figure>
                        <figure> STOPLOSS<span>{reportData.metadata.stop_loss}</span></figure>
                    </div>
                    </div>
                    <p>
                    <span className="smtb">TERMS &amp; CONDITIONS</span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vehicula iaculis aliquet. Vestibulum ut ullamcorper enim. Nulla id turpis feugiat, egestas tortor nec, malesuada nunc. Cras ac vulputate purus, vel aliquet ex. Maecenas eleifend tempus nisi. Pellentesque ullamcorper sapien vel ullamcorper iaculis. Mauris quis laoreet libero.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vehicula iaculis aliquet. Vestibulum ut ullamcorper enim. Nulla id turpis feugiat, egestas tortor nec, malesuada nunc. Cras ac vulputate purus, vel aliquet ex. Maecenas eleifend tempus nisi. Pellentesque ullamcorper sapien vel ullamcorper iaculis. Mauris quis laoreet libero.</p>
                </div>
                </div>
            </div>
            <hr className="white-border" />
            </div>  
            )
    } else {
        return (
            <div></div>
        )
    }
}

export default Report;