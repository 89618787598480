import MidCapReports from "../../components/organisms/MidCapReports";
import { useContext } from "react";
import { AuthContext } from '../../utils/AuthContext';

function MidCap() {
  const { isAuthenticated } = useContext(AuthContext);

    return (
        <div>
        <div className="banner-section inner-banner height">
          <div className="banner-divider">
            <div className="inbanner-text">
              <h1>MID CAP STOCKS</h1>
              <p className="mrg-10">Invest in companies with greater liquidity than small-cap equities, resulting in a lower risk profile. These equities are more volatile than large-cap stocks, but more stable than small-cap stocks. Explore these ideas now! <br /> <br />
                </p>
              {
                !isAuthenticated &&
                <a href='/login' className="btn-green">Sign Up/Login Now</a>
              }
            </div>
          </div>
        </div>
        <section className="spacing">
          <div className="container">
            <MidCapReports />
            <div className="report-divider">
              <figure className="smt-title">EXPLORE OTHER PORTFOLIOS</figure>
              <a href="small-cap" className="btn-green">SMALL CAP</a> <a href="large-cap" className="btn-green">LARGE CAP</a>
            </div>
          </div>
        </section>
      </div>
      )
    }
    
    export default MidCap;