import { Hub } from 'aws-amplify/utils';
import React, { createContext, useState, useEffect } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // null represents the initial loading state
    const [plans, setPlans] = useState('Free_Plan_Start');
    const [isLoading, setIsLoading] = useState(true);

    console.log("Is auth", isAuthenticated)
  
    useEffect(() => {
        Hub.listen('auth', ({ payload }) => {
            switch (payload.event) {
                case "signInWithRedirect":
                    setIsAuthenticated(true);
                    console.log('signed in')
                    break;
                case 'signedIn':
                  setIsAuthenticated(true)
                  break;
                case 'signedOut':
                  setIsAuthenticated(false)
                  break;
                case 'signInWithRedirect':
                    setIsAuthenticated(true)
                    break;
                default:
                    break;
              }
        })
      // Simulate an asynchronous check for authentication
      const checkAuthStatus = async () => {
          setIsLoading(true)
          try {
            const {accessToken} = (await fetchAuthSession()).tokens;
            if (accessToken) {
                setPlans(accessToken.payload['cognito:groups'])
                setIsAuthenticated(true)
            } else {
                setIsAuthenticated(false)
            }
            setIsAuthenticated(!!accessToken); // Update the authentication status based on the presence of a token
          } catch (e) {
              console.log(e);
              setIsAuthenticated(false);
          }
          setIsLoading(false)
        
      };

      checkAuthStatus()
    }, []);
  
    return (
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, isLoading, setIsLoading, plans }}>
        {children}
      </AuthContext.Provider>
    );
  };