
function SearchPageX() {
    return (
        <div>
        <div className="banner-section searchpage search-x inner-banner height">
          <div className="banner-divider">
            <div className="inbanner-text">
              <p>SORRY, WE COULD NOT FIND ANY RESULTS FOR <br />
                <strong>“SEARCH WORDS”</strong>
              </p>
              <div className="smt-btn"><a href={'/market-caps'} className="btn-green">EXPLORE MARKET CAPS</a><br />
                <a href={'/'} className="btn-green">GO BACK HOME</a>
              </div>
              <div className="skyimg"> </div>
            </div>
          </div>
        </div>
      </div>
          )
}

export default SearchPageX;