import { useState } from 'react';
import stockxprtApi from '../api/stockxprtApi';

const useResults = () => {
  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const searchApi = async searchTerm => {
    if (searchTerm !== '') {
      try{
        const response = await stockxprtApi.get('/search',{
          params: {
            q: searchTerm,
          }
        });
        console.log(response.data);
        setResults(response.data);
      } catch (err) {
          console.log(err)
          setErrorMessage('Something went wrong');
        }
    }
    else {
      setResults(null);
    }
  }
    
  const clearResults = () => {
      setResults(null);
  }

  return [searchApi, clearResults, results, errorMessage]
};

export default useResults;