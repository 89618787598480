import { useState, useEffect, useContext } from 'react';
import ArrowPurple from '../../assets/icons/arrow-right-purple.png';
import { updateUserAttributes, fetchUserAttributes, signOut } from "aws-amplify/auth";

function Profile() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneEditable, setPhoneEditable] = useState(true);
    const [emailEditable, setEmailEditable] = useState(true);

    const [editable, setEditable] = useState(false);

    async function getUserDetails() {
        console.log("Getting data");
        try {
          const userAttributes = await fetchUserAttributes();
          setEmail(userAttributes.email? userAttributes.email: '');
          setPhone(userAttributes.phone_number? userAttributes.phone_number.replace('+91',''): '');
          setFirstName(userAttributes.given_name? userAttributes.given_name: '');
          setLastName(userAttributes.family_name? userAttributes.family_name: '');
          setEmailEditable(!userAttributes.email_verified);
          setPhoneEditable(!userAttributes.phone_number_verified);
          setEditable(!userAttributes.identities);
        } catch (err) {
          console.log(err);
        }
      }

    // useEffect(() => {
    //     setEmail(user.email);
    //     setFirstName(user.given_name);
    //     setLastName(user.family_name);
    // },[])

    useEffect(() => {
        getUserDetails();
    },[])
    const handleSubmit = async () => {
        try {
            await updateUserAttributes({
              userAttributes: {
                given_name: firstName,
                family_name: lastName,
              }
            });
            // handle next steps
          } catch (error) {
            console.log(error);
          }
    }

    return (
        <div className="green-bg spacing profile-page">
            <div style={{textAlign: 'center', fontSize: '32px', color: 'rgb(102, 7, 119)', fontWeight: 'bold'}}>
                Welcome
            </div>
        <div style={{display: 'fex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} className="container">
            <form style={{flexDirection: 'column', display: 'flex', alignItems: 'center'}} onSubmit={handleSubmit}>
                <div className='form-input'>
                    <label style={{position: 'absolute', fontSize: '10px'}}>First Name</label>
                    <input style={!editable? {color:'grey'}: null} disabled={!editable} type="text" value={firstName} onChange={e => setFirstName(e.target.value)} placeholder='Enter Name' />
                </div>
                <div className='form-input'>
                    <label style={{position: 'absolute', fontSize: '10px'}}>Last Name</label>
                    <input style={!editable? {color:'grey'}: null} disabled={!editable} type="text" value={lastName} onChange={e => setLastName(e.target.value)} placeholder='Enter Name' />
                </div>
                {
                    phone &&
                    <div className='form-input'>
                        <label style={{position: 'absolute', fontSize: '10px'}}>Phone Number</label>
                        <span style={!phoneEditable? {color: 'grey'}: null} className="country-code">
                            +91
                        </span>
                        <input style={!phoneEditable? {color:'grey'}: null} maxLength={10} type="text" disabled={!phoneEditable} value={phone} onChange={e => setPhone(e.target.value)} placeholder='Enter Mobile Number' />
                    </div>
                }
                {
                    email &&
                    <div className='form-input'>
                        <label style={{position: 'absolute', fontSize: '10px'}}>Email Address</label>
                        <input style={!emailEditable? {color:'grey'}: null} disabled={!emailEditable} type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder='Enter Email Address' />
                    </div>
                }
                {
                    editable &&
                    <input style={{width: '200px', marginTop: '25px', fontSize: '14px'}} className="button" type="submit" value="Update Profile" />
                }
                
            </form>
        </div>
        <a href={'/subscribe'} style={{display: 'flex', color: '#660777', fontSize: '14px', alignItems: 'center', justifyContent: 'center', marginTop: '25px'}}>
            Subscriptions
            <img src={ArrowPurple} alt="purple-arrow" style={{height: '24px', marginLeft: '6px'}}/>
        </a>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '200px'}}>
            <button onClick={() => signOut()} className="button" style={{fontSize: '12px'}}>Logout</button>  
        </div>
      </div>
      )
}

export default Profile;