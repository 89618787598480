import { useNavigate } from "react-router-dom";
import upIcon from "../../assets/images/up.png";
import holdIcon from "../../assets/images/hold.png";
import sellIcon from "../../assets/images/sell.png";
import premiumIcon from "../../assets/images/premimu.svg";

const ReportCard = ({report,showPopup,setShowPopup}) => {
    const navigate = useNavigate();
    const tradeIcons = {'buy':upIcon, 'sell': sellIcon, 'hold': holdIcon}
    const readMore = (id, paid_report, stock_cap) => {
        if (!paid_report) {
            navigate('/report?id='+id)
        } else {
            setShowPopup(true);
        }
    }
    return (
        <div className="rp-box">
            <div className="report-img"><img src={report.logo} alt='companyLogo'/></div>
                <div className="report-name">
                    <span>{report.name}</span>
                    5 hours ago
                </div>
            <div className="bx-last">
            <a href='javascript:void(0)' onClick={() => readMore(report.id, report.paid_report, report.stock_cap)} className="readmore">Read More</a>
            {
                report.paid_report? (
                    <div onClick={()=>setShowPopup(!showPopup)} className="cor-pattern"><img src={premiumIcon} alt='premiumIcon'/></div>
                ): (
                    <div className="sm-btn"><img src={tradeIcons[report.trade_call]} alt={tradeIcons[report.trade_call]} /><br /> {report.trade_call}</div>
                )
            }                            
            </div>
            
        </div>
    )
}

export default ReportCard;