import { useSearchParams } from 'react-router-dom';
import SearchReports from "../../components/organisms/SearchReports"

function SearchPage() {
  const [searchParams] = useSearchParams();
  const q = searchParams.get('q')

    return (
        <div>
        <div className="banner-section searchpage inner-banner height2">
          <div className="banner-divider">
            <div className="inbanner-text">
              <p>SHOWING RESULTS FOR <br />
                <strong>"{q}"</strong>
              </p>
            </div>
          </div>
        </div>
        <section className="spacing">
          <div className="container">
            <SearchReports q={q} />
            {/* <div className="reports">
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <a href={'/'} className="sm-btn"><img src={upIcon} alt="upIcon" /><br /> Buy</a>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn gray"><img src={holdIcon} alt="holcIcon" /><br /> Hold</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={tataImg} alt="tataImg" /></div>
                <div className="report-name">
                  <span>TATA <br /> MOTORS</span>
                  5 hours ago
                </div>
                <div className="bx-last">
                  <a href={'/report?id=1234'} className="readmore">Read More</a>
                  <div className="sm-btn red"><img src={sellIcon} alt="sellIcon" /><br /> Sell</div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
              <div className="rp-box">
                <div className="report-img"><img src={landRoverImg} alt="landRoverImg" /></div>
                <div className="report-name">
                  <span>JAGUAR <br /> LAND ROVER</span>
                  1 day ago
                </div>
                <div className="bx-last">
                  <a href={'/'} className="readmore">Read More</a>
                  <div className="cor-pattern"><img src={premimumIcon} alt="premiumIcon" /></div>
                </div>
              </div>
            </div> */}
            <div className="report-divider">
              <figure className="smt-title">Can’t see what you’re looking for?</figure>
              <a href={'/market-caps'} className="btn-green">EXPLORE MARKET MAPS</a> 
            </div>
          </div>
        </section>
      </div>
      )
    }
    
    export default SearchPage;