import { useState, useEffect, useContext } from 'react';
import googleIcon from "../../assets/images/google.svg";
import { signIn, signUp, confirmSignIn, signInWithRedirect } from 'aws-amplify/auth';
import microsoftIcon from "../../assets/images/microsoft.svg"
import emailIcon from "../../assets/images/email.svg"
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';

function Login() {
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [error, setError] = useState('Please enter valid email/ phone');
    const { isAuthenticated, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();

    function isValidEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    }
    
    
    function isValidPhone(phone){
      return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
    }

    useEffect(() => {
      if (isAuthenticated) {
        navigate('/', {replace: true});
      }
    },[isAuthenticated])

    const passwordOptions = {
      num: "1234567890",
      specialChar: "!@#$%&'()*+,^-./:;<=>?[]_`{~}|",
      lowerCase: "abcdefghijklmnopqrstuvwxyz",
      upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    };

    function generateRandomPassword(length) {
      // initial state for password information
      let passInfo = "";
      // list of chosen characters
      const passChars = [];
      const characterAmount = length;
      // Add numbers
      passInfo += passwordOptions.num;
      passChars.push(getRandomChar(passwordOptions.num));
      // Add Special characters
      passInfo += passwordOptions.specialChar;
      passChars.push(getRandomChar(passwordOptions.specialChar));
      // Add lowercase characters
      passInfo += passwordOptions.lowerCase;
      passChars.push(getRandomChar(passwordOptions.lowerCase));
      // Add uppercase characters
      passInfo += passwordOptions.upperCase;
      passChars.push(getRandomChar(passwordOptions.upperCase));

      while(passChars.length < characterAmount) {
        // choose a random char from charInfo
        passChars.push(getRandomChar(passInfo));
      };

      // shuffle the list of characters using Fisher-Yates algorithm
      // see https://stackoverflow.com/a/2450976/8376184
      for(let i = passChars.length - 1; i > 0; i--){
        const swapIndex = Math.floor(Math.random() * (i + 1));
        const temp = passChars[i];
        passChars[i] = passChars[swapIndex];
        passChars[swapIndex] = temp;
      };

      // return the password character list concatenated to a string
      return passChars.join("");
    }

    function getRandomChar(fromString){
      return fromString[Math.floor(Math.random() * fromString.length)];
    }

    const initiateLogin = () => {
      signIn({
        username: isValidPhone(username)? '+91'+username: username,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP'
        }
      }).then((resp) => {
        console.log(resp);
        if (resp.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
          setOtpSent(true);
        } else {
          setOtpSent(false);
        }
      }).catch ((err) => {
        setOtpSent(false);
        try {
          const password = generateRandomPassword(12);
          signUp({
            username: isValidPhone(username)? '+91'+username: username,
            password: password
          }).then((resp) => {
            signIn({
              username: isValidPhone(username)? '+91'+username: username,
              options: {
                authFlowType: 'CUSTOM_WITHOUT_SRP'
              }
            }).then((resp) => {
              console.log(resp);
              if (resp.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
                setOtpSent(true);
              } else {
                setOtpSent(false);
              }
            }).catch ((err) => {
              console.log('OTP Not sent')
              setOtpSent(false);
            });
          })
        } catch {
          console.log("Something went wrong. Please try again later.")
        }
      })
    }

    const confirmOTP = () => {
      try {
        // to send the answer of the custom challenge
        confirmSignIn({ challengeResponse: otp }).then((resp) => {
          navigate('/', {replace: true})
          console.log(resp);
        }).catch((err) => {
          console.log(err);
          console.log('Something went wrong. Please try again later')
        })

      } catch (err) {
        console.log(err);
        console.log('Something went wrong. Please try again later')
      }
    }

    const onChangeEmailPhone = (event) => {
      if (!(isValidEmail(event.target.value) || isValidPhone(event.target.value))) {
        setError('Please enter valid email/ phone');
      } else {
        setError(null);
      }
      setUsername(event.target.value);
    }

    return (
        <div>
        <section className="loginpage contact-page green-bg spacing">
          <div className="container">
            <div className="login-divider">
              <div className="login-title">
                <figure>log in</figure>
                to your account
              </div>
              <div>
              <input style={{backgroundColor: 'rgba(255,255,255,0.3)', transition: '0.3s all', border: '0px', fontFamily: 'Gotham SSm A, Gotham SSm B, sans-serif', fontSize: '18px'}} value={username} onChange={onChangeEmailPhone} className="divider-part" placeholder={'Enter Email/ Phone Number'}  />
              <div style={{marginTop: '-15px', fontSize: '12px', color: '#660777'}}>
                {error}
              </div>
              </div>
              
              {
                otpSent &&
                <input style={{backgroundColor: 'rgba(255,255,255,0.3)', transition: '0.3s all', border: '0px', fontFamily: 'Gotham SSm A, Gotham SSm B, sans-serif', fontSize: '18px'}} value={otp} onChange={(e) => setOtp(e.target.value)} className="divider-part" placeholder={'Enter OTP'}  />
              }
              {
                !otpSent &&
                <button disabled={error} onClick={initiateLogin} style={error? {marginBottom: '30px', marginTop: '10px', opacity: '0.5'}: {marginBottom: '30px', marginTop: '10px', opacity: '1'}} className="button" >
                  Continue
                </button>
              } 
              {
                otpSent &&
                <button onClick={confirmOTP} disabled={otp.length !== 6} style={otp.length !== 6? {marginBottom: '30px', marginTop: '15px', opacity: '0.5'}: {marginBottom: '30px', marginTop: '15px'}} className="button" >
                  Confirm OTP
                </button>
              } 
              <a href={'javascript:void(0)'} onClick={() => signInWithRedirect({provider: 'Google'})} className="divider-part">
                <figure><img src={googleIcon} alt="googleIcon" /></figure>
                <span>Continue with Google</span>
              </a>
              {/* <a href={'/'} className="divider-part">
                <figure><img src={microsoftIcon} alt="microsoftIcon" /></figure>
                <span>Continue with Microsoft</span>
              </a>
              <a href={'/'} className="divider-part">
                <figure><img src={emailIcon} alt="emailIcon" /></figure>
                <span>Continue with Email</span>
              </a> */}
              {/* <div className="ft-signup">
                Don’t have an account?<br />
                <a href={'/'}>SIGN UP</a>
              </div> */}
            </div>
          </div>
        </section>  
      </div>
   )
}

export default Login;