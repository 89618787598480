import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../utils/AuthContext";

const PrivateRoutes = ({children}) => {
    const { isAuthenticated, isLoading } = useContext(AuthContext);
  
  if (!isLoading) {
    if (isAuthenticated) {
      return children
    } else {
      return <Navigate to="/login" replace />;
    }
  }
}

export default PrivateRoutes;