import logo from '../../assets/images/logo.png';

const Footer = () => {
    return (
        <footer className="green-bg">
            <div className="spacing">
                <div className="container text-center">
                <div className="footer-logo"><a href={'/'}><img style={{height: '50px', width: '248px'}} src={logo} alt='logo'/></a></div>
                <div className="footer-smo">
                    <a href="https://www.linkedin.com/in/rohan-tyagi-567231124/" target="_blank" rel="noreferrer">LinkedIn</a>
                    <a href={'/'}>Twitter</a>
                    <a href="https://www.instagram.com/stockxprt/" target="_blank" rel="noreferrer">Instagram</a>
                </div>
                <div style={{width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <a href={'/privacy-policy'}>Privacy Policy</a>
                    <a href={'/terms-and-conditions'} style={{marginLeft: '20px'}}>Terms & Conditions</a>
                </div>
                <div className="copyright">Copyright © Rohan Tyagi 2023</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
