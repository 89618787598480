import tick from "../../assets/images/tick.svg"
import thumbsup from "../../assets/images/thumbsup.svg"
import lock from "../../assets/images/lock.svg"
import aboutBanner from "../../assets/images/about-banner.svg"
import photo1 from "../../assets/images/Photo1.jpg"
import dnLink from "../../assets/images/DN-link.png"
import empower from "../../assets/images/empower.svg"
import key from "../../assets/images/Key.svg"

function AboutUs() {
    return (
            <div>
              <div className="banner-section height about-page">
                <div className="banner-divider">
                  <div className="inbanner-text">
                    <h1 className="black normal-m text-center">We make investing</h1>
                      <div className="border" />
                    <div className="aout-banner">
                      <figure>
                        <img src={tick} alt='tick' />
                        <span className="black">SIMPLER</span>
                      </figure>
                      <figure>
                        <img src={thumbsup} alt='thumbsup' />
                        <span className="black">BETTER</span>
                      </figure>
                      <figure>
                        <img src={lock} alt='lock' />
                        <span className="black">SAFER</span>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <section className="spacing light-green">
                <div className="container">
                  <div className="aboutdata">
                    <div className="aboutimg"><img src={aboutBanner} alt='aboutBanner' /></div>
                    <div className="ab-data">
                      <h3>So, why do we do it?</h3>
                      <p>A few brief sentences about the motivation behind the brand. Can also be the brand story or how it came to
                        be.</p>
                    </div>
                  </div>
                </div>
              </section>
              <section className="about-user lavender-color spacing">
                <div className="container">
                  <div className="userdata">
                    <div className="user-details">
                      <h3>And, how do we do it?</h3>
                      <p>Meet ROHAN TYAGI, the brains behind stockXprt.</p>
                      <p>Quisque neque sem, malesuada non aliquet vel, eleifend at enim. Vestibulum ante ipsum primis in faucibus orci
                        luctus et ultrices posuere cubilia curae.</p>
                    </div>
                    <div className="user-img">
                      <img src={photo1} alt='photo1' />
                    </div>
                  </div>
                  <div className="venture">
                    <p>Check out Rohan’s other venture</p>
                    <a href={'/'} className="dnlink"><img src={dnLink} alt='dnLink' /></a>
                  </div>
                </div>
              </section>
              <div className="light-green spacing">
                <div className="container">
                  <div className="vision-section">
                    <div className="vis-box">
                      <span>OUR VISION</span>
                      <img src={empower} alt='empower' />
                      <p>MAKING INVESTMENT DECISIONS EASY & ACCESSIBLE FOR ALL</p>
                    </div>
                    <div className="vis-box">
                      <span>OUR MISSION</span>
                      <img src={key} alt='key' />
                      <p>TO BE A RELIABLE RESEARCH ADVISORY PLATFORM TO EVERY INDIAN </p> <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
}

export default AboutUs;