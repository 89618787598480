import logo from '../../assets/images/logo.png';
import userImg from '../../assets/images/user.jpg';
import cross from "../../assets/images/cross.svg";
import mobileLogo from "../../assets/images/Mobile-logo.svg";
import { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import useResults from '../../hooks/stockSearchApi';
import { AuthContext } from '../../utils/AuthContext';

const Header = () => {
    const [showDropdown, setShowDropdown] = useState(null);
    const [showMobileDropdown, setShowMobileDropdown] = useState(null)
    const [showSearchBar, setShowSearchBar] = useState(null);
    const [searchApi, clearResults, results] = useResults();
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);

    const { isAuthenticated } = useContext(AuthContext);
    const loading = false;

    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 300);
        return () => clearTimeout(timer);
    }, [debouncedTerm]);

    useEffect(() => {
    if (term !== '') {
        searchApi(term)
    } else {
        clearResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    const clear = () => {
        clearResults();
        setDebouncedTerm('');
      };

    const toggleMenu = () => {
        if (showDropdown === 'show') {
            setShowDropdown(null)
        } else {
            setShowDropdown('show')
        }
    }

    const toggleMobileMenu = () => {
        if (showMobileDropdown === 'show') {
            setShowMobileDropdown(null)
        } else {
            setShowMobileDropdown('show')
        }
    }

    const toggleSearchBar = () => {
        if (showSearchBar === 'open') {
            clear();
            setShowSearchBar(null);
        } else {
            setShowSearchBar('open')
        }
    }

    return (
        <header className="header-bg fullgr">
            <section className="secongpage">
                <div className="container position">
                <div className="nave-section">
                    <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
                    <a className="navbar-brand" href={'/'}>
                        <img style={{height: '50px', width:'248px'}} src={logo} alt='logoImg'/>
                    </a>
                    <div className="collapse navbar-collapse" id="navbarsExample03">
                        <ul style={{alignItems: 'center'}} className="navbar-nav mr-auto">
                        <li className={"nav-item dropdown "+showDropdown}>
                            <a href="/#" className="nav-link dropdown-toggle" type="button" onClick={e=>{e.preventDefault(); toggleMenu()}}>
                                Market Caps
                            </a>
                            <div className={"dropdown-menu "+showDropdown} aria-labelledby="dropdown03">
                            <a className="dropdown-item" href="small-cap">SMALL CAP STOCKS</a>
                            <a className="dropdown-item" href="mid-cap">MID CAP STOCKS</a>
                            <a className="dropdown-item" href="large-cap">LARGE CAP STOCKS</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="about-us">About Us</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="contact-us">Contact Us</a>
                        </li>
                            {
                                isAuthenticated && !loading &&
                                <li className="nav-item">
                                    <a href="profile" className="user-profile nav-link">
                                        <figure><img style={{height: '25px', width: '25px'}} src={userImg} alt='userImg'/></figure>
                                    </a>
                                </li>
                            }
                        </ul>
                        <div className={"search-st "+showSearchBar}>
                            <a onClick={e=>{e.preventDefault(); toggleSearchBar()}} type="button" href="/#">
                                <svg width={50} height={40} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22.456 26.5384C20.3914 27.8552 17.9393 28.6182 15.3091 28.6182C7.95868 28.6182 2 22.6595 2 15.3091C2 7.95868 7.95868 2 15.3091 2C22.6595 2 28.6182 7.95868 28.6182 15.3091C28.6182 18.5835 27.4357 21.5817 25.4744 23.9L25.2818 23.7074L22.4533 26.5358L22.456 26.5384ZM23.8999 27.9823C21.4504 29.646 18.4933 30.6182 15.3091 30.6182C6.85411 30.6182 0 23.764 0 15.3091C0 6.85411 6.85411 0 15.3091 0C23.764 0 30.6182 6.85411 30.6182 15.3091C30.6182 19.1359 29.2141 22.6347 26.893 25.3185L38.746 37.1716L35.9176 40L23.8999 27.9823Z" fill="white" />
                                </svg>
                            </a>
                            <form className="form-inline my-2 my-md-0">
                                <input value={debouncedTerm} onChange={e => setDebouncedTerm(e.target.value)} style={{width: '100%'}} className="form-control" type="text" placeholder="Search" />
                            </form>
                        </div>
                        {/* {
                            isAuthenticated &&
                            <div className="user-profile">
                                <figure style={{height: '25px', width: '25px'}}><img style={{height: '25px', width: '25px'}} src={userImg} alt='userImg'/></figure>
                            </div>
                        } */}
                        {/* <div className="user-profile">
                        <figure style={{height: '50px', width: '50px', alignItems: 'center', justifyContent: 'center'}}><img style={{height: '25px', width: '25px'}} src={userImg} alt='userImg'/></figure>
                    </div> */}
                    </div>
                    
                    </nav>
                    {/* {
                        showSearchBar && <div style={{height: '450px', width: '100%', backgroundColor: 'black'}} />
                    } */}
                </div>
                </div>
                
                {/* Mobile Header Style Start Here*/}
                <div className="mobile-nave">
                    <div style={{height: '25px', width: '25px'}} className="logo-icon z-index"><Link to='/'><img src={mobileLogo} alt='mobileLogo'/></Link></div>
                    {
                        isAuthenticated && !loading &&
                        <a href="profile" className="user-profile z-index">
                            <figure style={{height: '25px', width: '25px'}}><img style={{height: '25px', width: '25px'}} src={userImg} alt='userImg'/></figure>
                        </a>
                    }
                    <nav style={{marginBottom: '5px'}} className="navbar navbar-expand-sm navbar-dark bg-dark z-index">
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" onClick={toggleMobileMenu}>
                            <svg width={25} height={25} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line y1={2} x2={20} y2={2} stroke="white" strokeWidth={3} />
                                <line y1={10} x2={20} y2={10} stroke="white" strokeWidth={3} />
                                <line y1={18} x2={20} y2={18} stroke="white" strokeWidth={3} />
                            </svg>
                        </button>
                    </nav>
                    <div className={"search-st "+showSearchBar}>
                            <a onClick={e=>{e.preventDefault(); toggleSearchBar()}} type="button" href="/#">
                                    <svg width={35} height={35} viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M22.456 26.5384C20.3914 27.8552 17.9393 28.6182 15.3091 28.6182C7.95868 28.6182 2 22.6595 2 15.3091C2 7.95868 7.95868 2 15.3091 2C22.6595 2 28.6182 7.95868 28.6182 15.3091C28.6182 18.5835 27.4357 21.5817 25.4744 23.9L25.2818 23.7074L22.4533 26.5358L22.456 26.5384ZM23.8999 27.9823C21.4504 29.646 18.4933 30.6182 15.3091 30.6182C6.85411 30.6182 0 23.764 0 15.3091C0 6.85411 6.85411 0 15.3091 0C23.764 0 30.6182 6.85411 30.6182 15.3091C30.6182 19.1359 29.2141 22.6347 26.893 25.3185L38.746 37.1716L35.9176 40L23.8999 27.9823Z" fill="white" />
                                    </svg>
                            </a>
                            {
                                showSearchBar === 'open' &&
                                <form className="form-inline my-2 my-md-0">
                                    <input value={debouncedTerm} onChange={e => setDebouncedTerm(e.target.value)} style={{width: '70%'}} className="form-control" type="text" placeholder="Search" />
                                </form>
                            }
                            
                    </div>
                </div>
                {/* Mobile Header Style End Here*/}
                {/* Mobile Menu Start Here*/}
                <div className={"collapse navbar-collapse mobile-navstyle "+showMobileDropdown} id="navbarsExample03">
                <div className="menu-divider">
                    <button className="target" onClick={toggleMobileMenu}><img src={cross} alt='cross'/></button>  
                    <div className="mobile-logo">
                        <a className="navbar-brand" href={'/'}>
                            <img src={logo} alt={logo} style={{width: '200px'}}/>
                        </a>
                    </div>
                    <ul className="navbar-nav mr-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href={'/'} id="dropdown03" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Market Caps</a>
                        <div className="dropdown-menu" aria-labelledby="dropdown03">
                            <a className="dropdown-item" href="small-cap">SMALL CAP STOCKS</a>
                            <a className="dropdown-item" href="mid-cap">MID CAP STOCKS</a>
                            <a className="dropdown-item" href="large-cap">LARGE CAP STOCKS</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="about-us">About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="contact-us">Contact Us</a>
                    </li>
                    </ul>
                </div>
                </div>
                {/* Mobile Menu End Here*/}
                <div className="mobilestylelogo"><a href={'/'}><img src={logo} alt={logo}/></a></div>
                {
                        showSearchBar && results && results.length > 0 &&
                        <div style={{height: '450px', width: '100%', backgroundColor: '#57c483', overflowY: 'scroll'}}>
                            {
                                results.map((item) => {
                                    return (
                                        <Link to={'/search-page?q='+item.trading_symbol} onClick={toggleSearchBar}>
                                            <div className='search-results'>
                                                {item.trading_symbol  + ' : ' + item.exchange}<br />
                                                <span>{item.name}</span>
                                            </div>
                                        </Link>
                                        
                                    )
                                })
                            }
                        </div>
                }
            </section>
            {/* <section className="searchBarSection">
                <div style={{backgroundColor: 'black', height: '30px', width: '300px', marginLeft: 'auto', marginRight: 'auto'}} />
            </section> */}
            </header>
    )
}

export default Header;