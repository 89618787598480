import { useState, useEffect } from 'react';
import stockxprtApi from "../../api/stockxprtApi";
import ReportCard from '../molecules/ReportCard';
import UnlockReport from '../molecules/UnlockReport';

const SearchReports = ({q}) => {
    const [topReports, setTopReports] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        stockxprtApi.get(`/top-reports`).then((resp)=>{
            setTopReports(resp.data)
        });
    },[]);

    return (
        <div className="reports">
            {
                topReports.map((report) => {
                    return(
                        <ReportCard report={report} showPopup={showPopup} setShowPopup={setShowPopup}/>
                    )
                    
                })
            }
            <UnlockReport showPopup={showPopup} setShowPopup={setShowPopup}/>
        </div>
    )
}

export default SearchReports;