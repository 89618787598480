import location from "../../assets/images/location.svg"
import mobile from "../../assets/images/mobile.svg"
import envelope from "../../assets/images/envelope.svg" 
import contactImage from "../../assets/images/contact-img.png"
function ContactUs() {
    return (
        <div>
        <section className="contact-page green-bg spacing">
          <div className="container">
            <div className="contact-cp">
              <div className="content-left">
                <h2>Connect with us today!</h2>
                <div className="contact-details">
                  <div className="iconcity"><img src={location} alt='location' /></div>
                  <div className="contact-divider">
                    201, Makhija Chambers,<br />
                    196, Off Turner Rd,<br />
                    <span></span>  
                    Bandra West, Mumbai,<br /> Maharashtra 400050
                  </div>
                </div>
                <div className="contact-details">
                  <div className="iconcity"><img src={mobile} alt='mobile' /></div>
                  <div className="contact-divider lineheight">
                    <a href={"tel:+919820094395"} className="phone">+91 98200 94395</a><br />
                    <a href={"tel:+919820094395"}  className="message-btn">CALL NOW</a> 
                  </div>
                </div>
                <div className="contact-details">
                  <div className="iconcity"><img src={envelope} alt='envelope'/></div>
                  <div className="contact-divider lineheight">
                    <a href={'mailto:rohan@stockXprt.com'} className="phone">rohan@stockXprt.com </a><br />
                    <a href={'mailto:rohan@stockXprt.com'} className="message-btn">SEND AN EMAIL</a> 
                  </div>
                </div>
              </div>
              <div className="contact-right">
                <img style={{height: '360px', width: '330px' }} src={contactImage} alt='contactImage' />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
}
export default ContactUs;