import { useState, useEffect, useContext } from 'react';
import premiumIcon from "../../assets/images/premimu.svg";
import { AuthContext } from '../../utils/AuthContext';

function Subscribe() {
  const {plans} = useContext(AuthContext);
  const [selection, setSelection] = useState(null);
  const [activeBox, setActiveBox] = useState(['sb-planbox active', 'sb-planbox', 'sb-planbox', 'sb-planbox', 'sb-planbox']);
  const [currentPlan, setCurrentPlan] = useState(null);
  const subscriptionOptions = ['Free_Plan', 'Plan_X', 'Largecap_Plan', 'Midcap_Plan', 'Smallcap_Plan']

  useEffect(() => {
    for (let i = 0; i < plans.length; i++) {
      if (plans[i] !== "ap-south-1_lTFowcVuh_Google") {
        setCurrentPlan(plans[i]);
      }
    }
  },[])

  useEffect(() => {
    let tempActiveBox = []
    for (let i=0; i<activeBox.length; i++) {
      if (subscriptionOptions[i] === currentPlan) {
        tempActiveBox.push('sb-planbox')
      } else {
        if (i === selection) {
          tempActiveBox.push('sb-planbox active')
        } else {
          tempActiveBox.push('sb-planbox')
        }
      }
      
    }
    setActiveBox(tempActiveBox);
  },[selection])
  
    return (
      <div>
      {/* <div className="banner-section inner-banner height">
        <div className="banner-divider">
          <div className="inbanner-text sbdiv">
            <p className="sb-banner-title"><small>Invest smarter. Invest better.</small>
              Read 5000+ reports and start growing today! 
            </p>
            <a href={'/'} className="smt-btn">Login</a> <a href={'/'} className="smt-btn fill-c">Sign Up</a>
          </div>
        </div>
      </div> */}
      <div className="subscribe-main spacing green-bg">
        <div className="container">
          <h2>choose the plan 
            <span>that’s right for you</span>
          </h2>
          <div style={{gap: '20px'}} className="subscribe-plan">
          <div className={activeBox[0]}>
              <div className="plan-title">
                <span>PLAN F</span>
                TOP REPORTS ONLY
              </div>
              <div className="plan-space">
                <div className="price-box">
                  3 MONTHS
                  <figure>FREE</figure>
                </div>
                <button href='{}' style={(selection===0 || currentPlan=== 'Free_Plan')? {background: '#000'}:null} disabled={selection===0 || currentPlan === 'Free_Plan'} onClick={()=>setSelection(0)} className="btn-green">{currentPlan==='Free_Plan'? 'CURRENT PLAN': (selection===0? 'SUBSCRIBE NOW': 'CHOOSE THIS PLAN')}</button>
              </div>
              {
                currentPlan === 'Free_Plan' &&
                <div style={{position: 'absolute', right: '-5px', bottom: '-5px'}}><img src={premiumIcon} alt='premiumIcon'/></div>
              }
              
            </div>
            <div className={activeBox[1]}>
              <div className="plan-title">
                <span>PLAN X</span>
                ALL MARKET CAPS
              </div>
              <div className="plan-space">
                <div className="price-box">
                  3 MONTHS
                  <figure>₹6,000</figure>
                </div>
                <button href='{}' style={(selection===1 || currentPlan=== 'x')? {background: '#000'}:null} disabled={selection===1 || currentPlan === 'x'} onClick={()=>setSelection(1)} className="btn-green">{currentPlan==='x'? 'CURRENT PLAN': (selection===1? 'SUBSCRIBE NOW': 'CHOOSE THIS PLAN')}</button>
              </div>
              {
                currentPlan === 'x' &&
                <div style={{position: 'absolute', right: '-5px', bottom: '-5px'}}><img src={premiumIcon} alt='premiumIcon'/></div>
              }
              
            </div>
            <div className={activeBox[2]}>
              <div className="plan-title">
                <span>PLAN L</span>
                LARGE CAP ONLY
              </div>
              <div className="plan-space">
                <div className="price-box">
                  3 MONTHS
                  <figure>₹5,000</figure>
                </div>
                <button href='{}' style={(selection===2 || currentPlan=== 'Largecap_Plan')? {background: '#100'}:null} disabled={selection===2 || currentPlan === 'Largecap_Plan'} onClick={()=>setSelection(2)} className="btn-green">{currentPlan==='Largecap_Plan'? 'CURRENT PLAN': (selection===2? 'SUBSCRIBE NOW': 'CHOOSE THIS PLAN')}</button>
              </div>
              {
                currentPlan === 'Largecap_Plan' &&
                <div style={{position: 'absolute', right: '-5px', bottom: '-5px'}}><img src={premiumIcon} alt='premiumIcon'/></div>
              }
            </div>
            <div className={activeBox[3]}>
              <div className="plan-title">
                <span>PLAN M</span>
                MID CAP ONLY
              </div>
              <div className="plan-space">
                <div className="price-box">
                  3 MONTHS
                  <figure>₹3,000</figure>
                </div>
                <button href='{}' style={(selection===3 || currentPlan=== 'Midcap_Plan')? {background: '#000'}:null} disabled={selection===3 || currentPlan === 'Midcap_Plan'} onClick={()=>setSelection(3)} className="btn-green">{currentPlan==='Midcap_Plan'? 'CURRENT PLAN': (selection===3? 'SUBSCRIBE NOW': 'CHOOSE THIS PLAN')}</button>
              </div>
              {
                currentPlan === 'Midcap_Plan' &&
                <div style={{position: 'absolute', right: '-5px', bottom: '-5px'}}><img src={premiumIcon} alt='premiumIcon'/></div>
              }
            </div>
            <div className={activeBox[4]}>
              <div className="plan-title">
                <span>PLAN S</span>
                SMALL CAP ONLY
              </div>
              <div className="plan-space">
                <div className="price-box">
                  3 MONTHS
                  <figure>₹2,000</figure>
                </div>
                <button href='{}' style={(selection===4 || currentPlan=== 'Smallcap_Plan')? {background: '#000'}:null} disabled={selection===4 || currentPlan === 'Smallcap_Plan'} onClick={()=>setSelection(4)} className="btn-green">{currentPlan==='Smallcap_Plan'? 'CURRENT PLAN': (selection===4? 'SUBSCRIBE NOW': 'CHOOSE THIS PLAN')}</button>
              </div>
              {
                currentPlan === 'Smallcap_Plan' &&
                <div style={{position: 'absolute', right: '-5px', bottom: '-5px'}}><img src={premiumIcon} alt='premiumIcon'/></div>
              }
            </div>
          </div>
          {
            (selection || selection === 0) &&
            <div className="detail-form">
              <div className="banner-space"><button type="submit" className="button">Subscribe</button></div>
            </div>
          }
        </div>
      </div>
    </div>
    )
}

export default Subscribe;