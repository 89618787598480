import SmallCapReports from "../../components/organisms/SmallCapReports";
import { useContext } from "react";
import { AuthContext } from '../../utils/AuthContext';

function SmallCap() {
  const { isAuthenticated } = useContext(AuthContext);

    return (
       <div>
        <div className="banner-section inner-banner height">
          <div className="banner-divider">
            <div className="inbanner-text">
              <h1>SMALL CAP STOCKS</h1>
              <p className="mrg-10">Invest in companies that show considerable growth potential and are accessible at comparatively affordable prices. These equities are more volatile, posing a high risk profile while also offering potentially significant profits. Explore these ideas now! <br /> <br />
               </p>
              {
                !isAuthenticated &&
                <a href='/login' className="btn-green">Sign Up/Login Now</a>
              }
            </div>
          </div>
        </div>
        <section className="spacing">
          <div className="container">
            <SmallCapReports />
            <div className="report-divider">
              <figure className="smt-title">EXPLORE OTHER PORTFOLIOS</figure>
              <a href="mid-cap" className="btn-green">MID CAP</a> <a href="large-cap" className="btn-green">LARGE CAP</a>
            </div>
          </div>
        </section>
      </div> 
       )
    }
    
    export default SmallCap;