import LargeCapReports from "../../components/organisms/LargeCapReports";
import { useContext } from "react";
import { AuthContext } from '../../utils/AuthContext';

function LargeCap() {
    const { isAuthenticated } = useContext(AuthContext);

    return (
            <div>
            <div className="banner-section inner-banner height">
            <div className="banner-divider">
                <div className="inbanner-text">
                <h1>LARGE CAP STOCKS</h1>
                <p className="mrg-10">Invest in market-leading companies that generate steady revenue and capital appreciation. These equities tend to move with the market cycle and hence pose a smaller risk than all other market caps. Explore these ideas now! <br /> <br />
                    </p>
                {
                    !isAuthenticated &&
                    <a href='/login' className="btn-green">Sign Up/Login Now</a>
                }
                </div>
            </div>
            </div>
            <section className="spacing">
            <div className="container">
                <LargeCapReports />
                <div className="report-divider">
                <figure className="smt-title">EXPLORE OTHER PORTFOLIOS</figure>
                <a href="small-cap" className="btn-green">SMALL CAP</a> <a href="mid-cap" className="btn-green">MID CAP</a>
                </div>
            </div>
            </section>
        </div>
        )
    }
    
    export default LargeCap;