
import premiumIcon from "../../assets/images/premimu.svg";

const UnlockReport = ({showPopup, setShowPopup}) => {
    return (
        <section className="popup" style={{display:showPopup?'block':'none'}}>
            <div className="popup__content">
                <div onClick={()=>setShowPopup(!showPopup)} className="close">
                    <span />
                    <span />
                </div>
                <p>To unlock this report and get unlimited access to over 1,000 premium reports</p>
                <a href={'/login'} className="btn-green">Sign Up/Login Now</a>
                <div className="ft-signup">
                    Are you already a member?<br />
                    <a href={'/login'}>LOG IN</a>
                </div>
                <div className="premimu-shap"><img src={premiumIcon} alt='premiumIcon'/></div>
            </div>
        </section>
    )
}

export default UnlockReport;